.triangle_up {
    position: absolute;
    top: 100%; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-style: solid;
    border-color: transparent transparent black transparent;
    opacity: 0.7;
    transform: translate(-50%, -20%);
}

.triangle_down {
    position: absolute;
    bottom: 110%; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-style: solid;
    border-color: transparent transparent black transparent;
    opacity: 0.7;
    transform: translate(-50%, -40%) rotate(180deg);
}

.triangle_right {
    position: absolute;
    right: 100%; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-style: solid;
    border-color: transparent transparent black transparent;
    opacity: 0.7;
    transform: translate(-90%, -20%) rotate(90deg);
}

.customtooltip.e-tooltip-wrap {
    border-radius: 4px;
    opacity: 1;
}

.customtooltip.e-tooltip-wrap.e-popup {
    background-color: #757575;
    border: 2px solid #757575;
}

.customtooltip.e-tooltip-wrap .e-tip-content {
    color: #fff;
    font-size: 12px;
    padding-left: "3px";
    padding-right: "3px";
    line-break: 100px;
}

