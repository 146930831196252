.TableStyle > table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-bottom: 1px solid gray;
}
  
.TableStyle > table th {
    border-top: 1px solid gray;
    border-right: 1px solid gray;
}
  
.TableStyle > table td {
    border-top: 1px solid gray;
    border-right: 1px solid gray;
}
  
.TableStyle > table th:first-child,
.TableStyle > table td:first-child {
    border-left: 1px solid gray;
}

.tableFixHead thead {   
    position: sticky;
    top: 0;
    z-index: 1;
}

.sticky-col {
    position: -webkit-sticky;
    position: sticky;
}
  
.col {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
    left: 0px;
}
  
.first-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
}
  
.second-col {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    left: 100px;
}

.third-col {
    width: 60pxpx;
    min-width: 60px;
    max-width: 60pxpx;
    left: 160px;
}

.scrollbar {
    min-height: 300px;
    overflow-y: auto;
 }