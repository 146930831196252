.polling tbody tr:nth-child(odd) {
    background-color: #e6f3ff;
}

.polling tbody tr:nth-child(even) {
    background-color: #f5faff;
}

.polling tbody tr td {
    padding-left: 10%;
}